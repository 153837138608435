<template>
  <div>
    <div>
      <head-layout :head-btn-options="headBtnOptions" :head-title="$t('cip.plat.app.appMenu.title.indexHeadTitle')" @head-add="getNew" @head-romve="headRomve">
      </head-layout>
      <grid-head-layout ref="searchFrom" :search-columns="searchColumns" v-model="searchForm"
                        @grid-head-search="searchChange" @grid-head-empty="searchReset">
      </grid-head-layout>
      <grid-layout ref="gridLayOut" :tableOptions="option" :tableData="data" :table-loading="loading" :page="page" :data-total="page.total"
                   @gird-handle-select-click="selectionChange" :gridRowBtn="gridRowBtn" @grid-edit="rowUpdate" @grid-romve="rowDel"
                   @row-update="addUpdate" @tree-load='treeLoad' @grid-addChild="handleAdd"
                   @page-current-change="onLoad"
                   @page-size-change="onLoad"
                   @page-refresh-change="onLoad"
      >

      </grid-layout>
      <el-drawer class="el-drawer__wrapper avue-dialog avue-crud__dialog" :visible.sync="isShow" size="70%"
                 style="margin-top: 50px;" :show-close="false">
        <template slot="title">
          <dialog-head-btn @dialog-head-save-click="save" @dialog-head-cancel-click="cancel" head-title="" icon="">
          </dialog-head-btn>
        </template>
        <form-layout v-if="isShow" :column="option.column" :dataForm="dataObj" ref="formLayout"></form-layout>
      </el-drawer>
    </div>

    <!-- <basic-container>
      <avue-crud :option="option" :table-loading="loading" :data="data" ref="crud" v-model="form"
        :permission="permissionList" :before-open="beforeOpen" :before-close="beforeClose" @row-del="rowDel"
        @row-update="rowUpdate" @row-save="rowSave" @search-change="searchChange" @search-reset="searchReset"
        @selection-change="selectionChange" @current-change="currentChange" @size-change="sizeChange"
        @refresh-change="refreshChange" @on-load="onLoad" @tree-load="treeLoad">
        <template slot="menuLeft">
          <el-button type="danger" size="small" icon="el-icon-delete" v-if="permission.menu_delete" plain
            @click="handleDelete">删 除
          </el-button>
        </template>
        <template slot-scope="scope" slot="menu">
          <el-button type="text" icon="el-icon-circle-plus-outline" size="small"
            @click.stop="handleAdd(scope.row, scope.index)"
            v-if="userInfo.role_name.includes('administrator') && scope.row.category === 1">新增子项
          </el-button>
        </template>
        <template slot-scope="{row}" slot="source">
          <div style="text-align:center">
            <i :class="row.source" />
          </div>
        </template>
      </avue-crud>
    </basic-container> -->
  </div>
</template>

<script>
import {getLazyAppList as getLazyList, remove, update, add, getMenu } from "@/api/system/menu";
import { mapGetters } from "vuex";
import iconList from "@/config/iconList";
import func from "@/util/func";
import { getMenuTree } from "@/api/system/menu";
import HeadLayout from "@/views/components/layout/head-layout"
import GridLayout from "@/views/components/layout/grid-layout";
import dialogHeadBtn from "@/views/components/layout/dialog-head-btn";
import formLayout from '@/views/components/layout/form-layout'
export default {
  components: {
    HeadLayout,
    GridLayout,
    dialogHeadBtn,
    formLayout
  },
  data() {
    return {

      isShow: false,
      dataObj: '',

      searchColumns: [
        {
          label: "",
          prop: "name",
          span: 4,
          type:"input",
          search: true,
          placeholder:this.$t("cip.cmn.rule.inputWarning")+this.$t("cip.plat.sys.menu.field.name"),
        },
        {
          label: "",
          prop: "code",
          span: 4,
          type:"input",
          search: true,
          placeholder:this.$t("cip.cmn.rule.inputWarning")+this.$t("cip.plat.app.appMenu.field.code"),
        },
        {
          label: "",
          placeholder:this.$t("cip.cmn.rule.inputWarning")+this.$t("cip.plat.app.appMenu.field.alias"),
          prop: "alias",
          span: 4,
          type:"input",
          search: true,
        },
      ],
      form: {},
      query: {},
      loading: true,
      selectionList: [],
      parentId: 0,
      page: {
        pageSize: 1000,
        pageSizes: [10, 20,30, 40, 50, 100,1000],
        currentPage: 1,
        total: 0,
      },
      option: {
        lazy: true,
        tip: false,
        simplePage: true,
        searchShow: false,
        searchMenuSpan: 6,
        dialogWidth: "60%",
        tree: true,
        border: true,
        index: true,
        selection: true,
        viewBtn: false,
        menuWidth: 300,
        dialogClickModal: false,
        column: [
          {
            label: this.$t("cip.plat.sys.menu.field.name"),
            prop: "name",
            search: true,
            align: "left",
            overHidden: true,
            rules: [
              {
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning")+this.$t("cip.plat.sys.menu.field.name"),
                trigger: "blur"
              }
            ]
          },
          {
            label:this.$t("cip.plat.app.appMenu.field.path"),
            prop: "path",
            width: 200,
            align: "left",
            overHidden: true,
            rules: [
              {
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning")+this.$t("cip.plat.app.appMenu.field.path"),
                trigger: "blur"
              }
            ]
          },
          {
            label: this.$t("cip.plat.app.appMenu.field.parentId"),
            prop: "parentId",
            type: "tree",
            dicData: [],
            hide: true,
            addDisabled: false,
            width: 200,
            align: "left",
            overHidden: true,
            props: {
              label: "title"
            },
            rules: [
              {
                required: false,
                message: this.$t("cip.cmn.rule.inputWarning")+this.$t("cip.plat.app.appMenu.field.parentId"),
                trigger: "click"
              }
            ]
          },
          // {
          //   label: this.$t("cip.plat.app.appMenu.field.source"),
          //   prop: "source",
          //   type: "icon",
          //   slot: true,
          //   iconList: iconList,
          //   rules: [
          //     {
          //       required: true,
          //       message: this.$t("cip.cmn.rule.selectWarning")+this.$t("cip.plat.app.appMenu.field.source"),
          //       trigger: "click"
          //     }
          //   ]
          // },
          {
            label: this.$t("cip.plat.app.appMenu.field.code"),
            prop: "code",
            search: true,
            width: 100,
            align: "left",
            overHidden: true,
            rules: [
              {
                required: true,
                message: this.$t("cip.cmn.rule.selectWarning")+this.$t("cip.plat.app.appMenu.field.code"),
                trigger: "blur"
              }
            ]
          },
          // {
          //   label: this.$t("cip.plat.app.appMenu.field.category"),
          //   prop: "category",
          //   type: "radio",
          //   dicData: [
          //     {
          //       label: this.$t("cip.plat.app.appMenu.field.menus"),
          //       value: 1
          //     },
          //     {
          //       label: this.$t("cip.plat.app.appMenu.field.button"),
          //       value: 2
          //     }
          //   ],
          //   hide: true,
          //   rules: [
          //     {
          //       required: true,
          //       message: this.$t("cip.cmn.rule.inputWarning")+this.$t("cip.plat.app.appMenu.field.category"),
          //       trigger: "blur"
          //     }
          //   ]
          // },
          // {
          //   label:this.$t("cip.plat.app.appMenu.field.alias"),
          //   prop: "alias",
          //   search: true,
          //   rules: [
          //     {
          //       required: true,
          //       message: this.$t("cip.cmn.rule.selectWarning")+this.$t("cip.plat.app.appMenu.field.alias"),
          //       trigger: "blur"
          //     }
          //   ]
          // },
          {
            label:this.$t("cip.plat.app.appMenu.field.color"),
            prop: "color",
            search: true,
            width: 150,
            align: "left",
            overHidden: true,
            rules: [
              {
                required: true,
                message: this.$t("cip.cmn.rule.selectWarning")+this.$t("cip.plat.app.appMenu.field.color"),
                trigger: "blur"
              }
            ]
          },
          {
            label:this.$t("cip.plat.app.appMenu.field.filePath"),
            prop: "filePath",
            search: true,
            width: 150,
            align: "left",
            overHidden: true,
            rules: [
              {
                required: true,
                message: this.$t("cip.cmn.rule.selectWarning")+this.$t("cip.plat.app.appMenu.field.filePath"),
                trigger: "blur"
              }
            ]
          },
          // {
          //   label: this.$t("cip.plat.app.appMenu.field.isOpen"),
          //   prop: "isOpen",
          //   type: "radio",
          //   disabled: false,
          //   dicData: [
          //     {
          //       label: this.$t("cip.plat.app.appMenu.field.no"),
          //       value: 1
          //     },
          //     {
          //       label: this.$t("cip.plat.app.appMenu.field.yes"),
          //       value: 2
          //     }
          //   ],
          //   value: 1,
          //   rules: [
          //     {
          //       required: true,
          //       message: this.$t("cip.cmn.rule.inputWarning")+this.$t("cip.plat.app.appMenu.field.isOpen"),
          //       trigger: "blur"
          //     }
          //   ]
          // },
          {
            label: this.$t("cip.plat.app.appMenu.field.status"),
            prop: "status",
            type: "radio",
            align:'center',
            width: 100,
            overHidden: true,
            dataType: 'number',
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict/dictionary?code=yes_no",
          },
          {
            label: this.$t("cip.plat.app.appMenu.field.sort"),
            prop: "sort",
            type: "number",
            align:'right',
            row: true,
            width: 80,
            overHidden: true,
            rules: [
                {
                  required: true,
                  message: this.$t(`cip.cmn.rule.inputWarning`) + this.$t(`cip.cmn.rule.orderNum`),
                  trigger: "blur",
                },
                {
                  type: 'number',
                  min: 0,
                  max: 1000000000,
                  message: this.$t(`cip.cmn.rule.inputWarning`) + this.$t(`cip.cmn.rule.ten`),
                  trigger: "blur",
                }
              ],
          },

          {
            label: this.$t("cip.plat.app.appMenu.field.remark"),
            prop: "remark",
            type: "textarea",
            minRows: 2,
            width: 230,
            align:'center',
            overHidden: true,
          }
        ]
      },
      data: []
    };
  },
  watch: {
    'form.category'() {
      const category = func.toInt(this.form.category);
      this.$refs.crud.option.column.filter(item => {
        if (item.prop === "path") {
          item.rules[0].required = category === 1;
        }
        if (item.prop === 'isOpen') {
          item.disabled = category === 2;
        }
      });
    },
  },
  computed: {
    ...mapGetters(["userInfo", "permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.menu_add, false),
        viewBtn: this.vaildData(this.permission.menu_view, false),
        delBtn: this.vaildData(this.permission.menu_delete, false),
        editBtn: this.vaildData(this.permission.menu_edit, false)
      };
    },
    headBtnOptions() {
        let result = [];
        //新增按钮
        if (this.permission.appMenu_add) {
          result.push({
            label: this.$t("cip.cmn.btn.addBtn"),
            emit: "head-add",
            type: "button",
            icon: ""
          });
        }
        if (this.permission.appMenu_delete) {
          result.push({
            label: this.$t("cip.cmn.btn.delBtn"),
            emit: "head-romve",
            type: "button",
            icon: ""
          });
        }
        return result
    },
    gridRowBtn() {
      let result = [];
      //新增按钮
      if (this.permission.appMenu_edit) {
        result.push({
          label: this.$t("cip.cmn.btn.editBtn"),
          emit: "grid-edit",
          type: "text",
          icon: ""
        });
      }
      if (this.permission.appMenu_delete) {
        result.push({
          label: this.$t("cip.cmn.btn.delBtn"),
          emit: "grid-romve",
          type: "text",
          icon: ""
        });
      }
      if (this.permission.appMenu_addChild) {
        result.push({
          label: this.$t("cip.cmn.btn.addChildBtn"),
          emit: "grid-addChild",
          type: "text",
          icon: ""
        });
      }
      return result
    },

    ids() {
      let ids = [];
      this.selectionList.forEach(ele => {
        ids.push(ele.id);
      });
      return ids.join(",");
    }
  },
  methods: {
    save() {
      this.$refs.formLayout.$refs.form.validate(valid => {
        if (valid) {
          let obj = this.$refs.formLayout.dataForm
          let requestType = obj.hasOwnProperty('id') ? update : add
          requestType(obj).then(() => {
            this.parentId = 0
            this.onLoad(this.page);
            this.initData()
            this.dataObj = ''
            this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess"),
            });
            this.isShow = false
            //
          }, error => {
            window.console.log(error);
            this.$refs.formLayout.$refs.form.allDisabled=false
          });
        } else {
          this.$refs.formLayout.$refs.form.allDisabled=false
        }
      })

    },
    cancel() {
      this.isShow = false;
      this.dataObj = ''
    },
    getNew() {
      this.$router.push({
        path:'/menu/appMenuAdd',
        query:{
          type:'add',
         }
      })

    },
    headRomve() {
      this.handleDelete()
    },
    initData() {
      getMenuTree().then(res => {
        const column = this.findObject(this.option.column, "parentId");
        column.dicData = res.data.data;
      });
    },
    handleAdd(row) {
      this.$router.push({
        path:'/menu/appMenuAdd',
        query:{
          type:'addChild',
          id:row.id,
         }
      })
    },
    rowSave(row, done, loading) {
      add(row).then((res) => {
        // 获取新增数据的相关字段
        const data = res.data.data;
        row.id = data.id;
        this.$message({
          type: "success",
          message: this.$t("cip.cmn.msg.success.operateSuccess"),
        });
        // 数据回调进行刷新
        // done(row);
      }, error => {
        window.console.log(error);
        loading();
      });
    },
    rowUpdate(row) {
      let data=encodeURIComponent(JSON.stringify(row))
      this.$router.push({
        path:'/menu/appMenuEdit',
        query:{
          type:'edit',
          id:row.id
        }
      })
    },
    rowDel(row) {
       this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),//确定
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
           remove(row.id).then(()=>{
            this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess"),
            });
            this.onLoad()
          }).finally(()=>{
            this.onLoad()
          })
        })

    },
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
        return;
      }
       this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(this.ids);
        })
        .then(() => {
          // 刷新表格数据并重载
          this.data = [];
          this.parentId = 0;
          this.$refs.gridLayOut.selectionClear();
          // 表格数据重载
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
        });
    },
    searchReset() {
      this.query = {};
      this.parentId = 0;
      this.onLoad(this.page);
    },
    searchChange(params) {
      this.query = params;
      this.parentId = '0';
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
      // done();
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
      this.$refs.gridLayOut.selectionClear()
    },
    beforeOpen(done, type) {
      if (["add", "edit"].includes(type)) {
        this.initData();
      }
      if (["edit", "view"].includes(type)) {
        getMenu(this.form.id).then(res => {
          this.form = res.data.data;
        });
      }
      // done();
    },
    beforeClose() {
      this.parentId = "";
      const column = this.findObject(this.option.column, "parentId");
      column.value = "";
      column.addDisabled = false;
      // done();
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    refreshChange() {
      this.onLoad(this.page, this.query);
    },
    onLoad(page = {}, params = {}) {
      this.page = page;
      this.loading = true;
      getLazyList(this.parentId, page.currentPage, page.pageSize , Object.assign(params, this.query)).then(res => {
       let data = res.data.data;
       this.data = data.records;
        // this.page.total = data.total;
        this.loading = false;
        this.$refs.gridLayOut.grid.refreshTable()
        // this.$refs.gridLayOut.selectionClear()
      });
    },
    treeLoad(tree, treeNode, resolve) {
      const parentId = tree.id;
      getLazyList(parentId).then(res => {
        resolve(res.data.data.records);
      });
    }
  },
  mounted() {
    this.onLoad(this.page, {});
  },
  created() {
    this.initData()
  },
};
</script>

<style>
</style>
